@tailwind base;
@tailwind components;
@tailwind utilities;

.input-style {
    width: 100%; /* 또는 적절한 최대 너비를 설정합니다 */
    padding: 8px 12px;
    margin: 8px 0; /* 필드 사이의 수직 마진을 조정합니다 */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 약간의 그림자를 추가하여 깊이감을 줍니다 */
    font-size: 14px; /* 적절한 폰트 크기 */
    color: #333; /* 폰트 색상 */
    background-color: #fff; /* 배경색 */
    transition: border-color 0.2s, box-shadow 0.2s; /* 부드러운 전환 효과 */
    ;
  }
  
  .input-style:focus {
    outline: none;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* 포커스 시 그림자 효과 */
  }
  

  /* custom-descriptions 클래스 내부의 라벨과 컨텐츠에 대한 스타일을 지정합니다 */
.custom-descriptions .ant-descriptions-item-label {
    width: 12.5% !important; /* 라벨 영역의 너비를 감소시킵니다 */
  }
  
  .custom-descriptions .ant-descriptions-item-content {
    width: 87.5% !important; /* 컨텐츠 영역의 너비를 늘립니다 */
  }